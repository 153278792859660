import React from 'react';
import { useSettingsContext } from '../../../../../Context/SettingsContext';
import { treatMessage } from '../../../../../utils/treatMessage';
import Typography from '../../../../Typography';
import * as S from './styles';

const REGEX_LINK = /##\s*link\s*;\s*([^;]+?)\s*;\s*([^#]+?)\s*##/gi;

const MessageWithLinks = ({ message }) => {
  const { customSettings } = useSettingsContext();
  const textColor = customSettings?.layout?.backgroundUserColor;
  const parseMessageWithLinks = (message) => {
    const parts = [];
    let lastIndex = 0;
    let match;
    // eslint-disable-next-line no-cond-assign
    while ((match = REGEX_LINK.exec(message)) !== null) {
      const [fullMatch, linkText, linkUrl] = match;
      const startIndex = match.index;

      if (startIndex > lastIndex) {
        parts.push(
          <S.NotLinkMessage
            key={`text-${lastIndex}`}
            dangerouslySetInnerHTML={{
              __html: treatMessage(message?.slice(lastIndex, startIndex)),
            }}
          />
        );
      }

      parts.push(
        <S.LinkMessage
          key={`link-${startIndex}`}
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          title={linkUrl}
          color={textColor}
        >
          <Typography variant="body-small-strong" className="linkMessage">
            {treatMessage(linkText)}
          </Typography>
        </S.LinkMessage>
      );

      lastIndex = startIndex + fullMatch?.length;
    }

    if (lastIndex < message?.length) {
      parts.push(
        <S.NotLinkMessage
          key={`text-${lastIndex}`}
          dangerouslySetInnerHTML={{
            __html: treatMessage(message?.slice(lastIndex)),
          }}
        />
      );
    }
    return parts;
  };

  return <S.Message>{parseMessageWithLinks(message?.message)}</S.Message>;
};

export default MessageWithLinks;
