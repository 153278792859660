/* eslint-disable react/no-danger */
import React from 'react';

import SimpleMessage from './components/SimpleMessage';
import MessageWithLinks from './components/MessageWithLinks';

const MessageText = ({ message }) => {
  const verifyMessageHasHiperLink = (message, from) => {
    if (!message || typeof message !== 'string' || from === 'user')
      return false;
    const hiperLinkPattern = /##[^;]+;[^;]+;[^#]+##/g;
    return hiperLinkPattern.test(message.replace(/\s+/g, ''));
  };
  const returnMessageComponent = (message) => {
    const hasHiperLink = verifyMessageHasHiperLink(
      message?.message,
      message?.from
    );
    if (hasHiperLink) return <MessageWithLinks message={message} />;
    return <SimpleMessage message={message} />;
  };
  return returnMessageComponent(message);
};

export default MessageText;
