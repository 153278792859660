import React, { useState, useEffect, useContext, useRef } from 'react';

import { useSettingsContext } from '../../Context/SettingsContext';
import { ChatContext } from '../../Context/ChatContext';

import * as S from './styles';

import {
  setLibrasToTranslate,
  setVoiceToTranslate,
  loadedPlayer,
  hasRybena,
  openPlayer,
  restartPlayer,
  filterMessagesWithoutTranslate,
  translate,
} from '../../utils/rybena';

import HandTranslate from '../../assets/icons/hand-translate.svg';
import VoiceTranslate from '../../assets/icons/translate-voice.svg';
import RestartTranslate from '../../assets/icons/RESTART_TRANSLATE.svg';
import { filterMessagesBot } from '../../utils/filterMessagesBot';
import { messages } from '../../Context/messagesMemory';

/*
  Botões responsáveis por mudar a tradução do player do Rybena ou para Libras ou por Voz
*/
const ButtonsRybena = ({ id }) => {
  const [mainColor, setMainColor] = useState();
  const [typeTranslate, setTypeTranslate] = useState('libras');
  const { bot } = useSettingsContext();
  const [isKeyboardFocus, setIsKeyboardFocus] = useState(false);
  const buttonsRef = useRef([]);

  const { setChangeTranslate } = useContext(ChatContext);

  useEffect(() => {
    if (bot) {
      setMainColor(bot?.channel?.settings?.mainColor);
    }

    const handleMouseDown = () => setIsKeyboardFocus(false);
    const handleKeyDown = (e) => {
      if (e.key === 'Tab' || e.key === 'Enter' || e.key === ' ') {
        setIsKeyboardFocus(true);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const switchTranslate = (typeTranslateParam) => {
    if (
      hasRybena() &&
      !!loadedPlayer() &&
      typeTranslate !== typeTranslateParam
    ) {
      setTypeTranslate(typeTranslateParam);
      openPlayer();
      const messagesFromBot = filterMessagesBot(messages);

      if (messagesFromBot.length) {
        const messagesWithoutTranslate =
          filterMessagesWithoutTranslate(messagesFromBot);

        const messageToTranslate = messagesWithoutTranslate[0];

        setChangeTranslate(true);
        localStorage.setItem('TYPE_TRANSLATE', typeTranslateParam);
        if (typeTranslateParam === 'libras') {
          try {
            setLibrasToTranslate();
          } catch (error) {
            console.log('Error: ', error);
          }
        }

        if (typeTranslateParam === 'voz') {
          try {
            setVoiceToTranslate();
          } catch (error) {
            console.log('Error: ', error);
          }
        }
        if (messagesWithoutTranslate.length) {
          translate(messageToTranslate);
        }
      }
    }
  };

  return (
    <S.Container id={id}>
      <S.Button
        ref={(el) => {
          buttonsRef.current[0] = el;
        }}
        onClick={() => switchTranslate('libras')}
        title="libras"
        mainColor={mainColor}
        tabIndex="0"
        onKeyDown={(e) => {
          if (e?.code === 'Enter') {
            switchTranslate('libras');
          }
        }}
        className={isKeyboardFocus ? 'keyboard-focus' : ''}
      >
        <img src={HandTranslate} alt="libras" />
      </S.Button>
      <S.Button
        ref={(el) => {
          buttonsRef.current[1] = el;
        }}
        onClick={() => switchTranslate('voz')}
        title="voz"
        mainColor={mainColor}
        tabIndex="0"
        onKeyDown={(e) => {
          if (e?.code === 'Enter') {
            switchTranslate('voz');
          }
        }}
        className={isKeyboardFocus ? 'keyboard-focus' : ''}
      >
        <img src={VoiceTranslate} alt="voice" />
      </S.Button>
      <S.Button
        ref={(el) => {
          buttonsRef.current[2] = el;
        }}
        onClick={() => restartPlayer()}
        title="retomar tradução"
        mainColor={mainColor}
        tabIndex="0"
        onKeyDown={(e) => {
          if (e?.code === 'Enter') {
            restartPlayer();
          }
        }}
        className={isKeyboardFocus ? 'keyboard-focus' : ''}
      >
        <img src={RestartTranslate} alt="restart-translate" />
      </S.Button>
    </S.Container>
  );
};

export default ButtonsRybena;
