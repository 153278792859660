import React, { useEffect, useRef } from 'react';
import messageTypes from '../../../../constants/messageTypes';
import * as S from './styles';

const InputFile = ({ onChange }) => {
  const isKeyboardFocus = useRef(false);

  const getFileExtension = (file) => {
    const nameSplitByDot = file.name.split('.');
    return nameSplitByDot[nameSplitByDot.length - 1].toLowerCase();
  };

  const handleFileChange = async (e) => {
    e.persist();
    const file = e.target.files?.[0];

    if (file) {
      const fileExtension = getFileExtension(file);
      const fileProperties = {
        name: file.name,
        ext: fileExtension,
        size: file.size,
        title: file.name,
      };

      const reader = new FileReader();
      await reader.readAsDataURL(file);

      const imagesExtensions = ['jpeg', 'png', 'jpg', 'gif', 'bmp'];
      if (imagesExtensions.includes(fileExtension)) {
        await reader.addEventListener(
          'load',
          () =>
            onChange({
              message: reader.result,
              type: messageTypes.IMAGE,
              ext: fileExtension,
              properties: { image: reader.result },
            }),
          false
        );
        return;
      }

      const audiosExtensions = [
        'mp3',
        'wav',
        'ogg',
        'oga',
        'mpga',
        'opus',
        'wav',
        'x-wav',
      ];
      if (audiosExtensions.includes(fileExtension)) {
        await reader.addEventListener(
          'load',
          () =>
            onChange({
              message: reader.result,
              type: messageTypes.AUDIO,
              ext: fileExtension,
              properties: {
                audio: { ...fileProperties, fileUrl: reader.result },
              },
            }),
          false
        );
        return;
      }

      const videoExtensions = ['mp4', 'm4v'];
      if (videoExtensions.includes(fileExtension)) {
        await reader.addEventListener(
          'load',
          () =>
            onChange({
              message: reader.result,
              type: messageTypes.VIDEO,
              ext: fileExtension,
              properties: {
                video: { ...fileProperties, fileUrl: reader.result },
              },
            }),
          false
        );
        return;
      }

      const documentExtensions = [
        'doc',
        'docx',
        'txt',
        'csv',
        'pdf',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
      ];
      if (documentExtensions.includes(fileExtension)) {
        await reader.addEventListener(
          'load',
          async () =>
            onChange({
              message: reader.result,
              type: messageTypes.DOCUMENT,
              ext: fileExtension,
              properties: {
                document: { ...fileProperties, fileUrl: reader.result },
              },
            }),
          false
        );
        return;
      }

      const compressionDocumentExtensions = ['zip', 'rar', 'gz', '7z'];
      if (compressionDocumentExtensions.includes(fileExtension)) {
        await reader.addEventListener(
          'load',
          () =>
            onChange({
              message: reader.result,
              type: messageTypes.DOCUMENT,
              ext: fileExtension,
              properties: {
                document: { ...fileProperties, fileUrl: reader.result },
              },
            }),
          false
        );
      }
    }
  };

  const onInputClick = (event) => {
    event.target.value = '';
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        isKeyboardFocus.current = true;
      }
    };

    const handleMouseDown = () => {
      isKeyboardFocus.current = false;
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleFocus = (event) => {
    if (isKeyboardFocus.current) {
      event.target.style.outline = '3px solid rgba(32, 32, 32, 0.7)';
      event.target.style.outlineOffset = '3px';
      event.target.style.border = 'none';
      event.target.style.borderRadius = '4px';
      event.target.style.transition =
        'box-shadow 0.2s ease-in-out, outline 0.2s ease-in-out, outline-offset 0.2s ease-in-out';
    }

    const inputMessage = document.querySelectorAll('.input-message');

    if (inputMessage?.length) {
      inputMessage.forEach((li) => {
        li.style.border = 'none';
      });
    }
  };

  const handleBlur = (event) => {
    event.target.style.border = 'none';
    event.target.style.outline = 'none';
    event.target.style.outlineOffset = '0';
    event.target.style.boxShadow = 'none';
  };

  return (
    <S.Container htmlFor="input-file">
      <input
        type="file"
        id="input-file"
        onChange={handleFileChange}
        onClick={onInputClick}
        autoComplete="off"
      />
      <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        tabIndex="0"
        style={{ outline: 'none', border: 'none' }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            const fileInput = document.getElementById('input-file');
            fileInput.click();
          }
        }}
      >
        <path
          d="M15.4688 4.28125C14.4922 3.34375 12.9688 3.34375 11.9922 4.28125L4.80469 11.4688C3.16406 13.1094 3.16406 15.8047 4.80469 17.4453C6.44531 19.0859 9.14062 19.0859 10.7812 17.4453L16.7188 11.5078C17.1484 11.0781 17.8125 11.0781 18.2422 11.5078C18.6719 11.9375 18.6719 12.6016 18.2422 13.0312L12.3047 18.9688C9.80469 21.4688 5.78125 21.4688 3.28125 18.9688C0.78125 16.4688 0.78125 12.4453 3.28125 9.94531L10.4688 2.75781C12.2656 0.921875 15.1953 0.921875 16.9922 2.75781C18.8281 4.55469 18.8281 7.48438 16.9922 9.28125L10.1172 16.1562C9.02344 17.2891 7.1875 17.2891 6.09375 16.1562C4.96094 15.0625 4.96094 13.2266 6.09375 12.1328L11.7188 6.50781C12.1484 6.07812 12.8125 6.07812 13.2422 6.50781C13.6719 6.9375 13.6719 7.60156 13.2422 8.03125L7.61719 13.6562C7.38281 13.9297 7.38281 14.3594 7.61719 14.6328C7.89062 14.8672 8.32031 14.8672 8.59375 14.6328L15.4688 7.75781C16.4062 6.78125 16.4062 5.25781 15.4688 4.28125Z"
          fill="#979AA5"
        />
      </svg>
    </S.Container>
  );
};

export default InputFile;
