import React, { memo, useEffect, useState } from 'react';
import cc from 'classcat';

import { useSettingsContext } from '../../Context/SettingsContext';
import TimeOnMessage from './components/TimeOnMessage';
import DeliveredFile from './components/DeliveredFile';
import { messages } from '../../Context/messagesMemory';

import * as S from './styles';
import { hasRybena, stopTranslate, translate } from '../../utils/rybena';

export default (MessageComponent) => {
  const MessageWrapper = (props) => {
    const { message, channel, focus } = props;
    const { fontSize, customSettings } = useSettingsContext();
    const [countFocus, setCountFocus] = useState(0);
    const [isMouseClick, setIsMouseClick] = useState(false);

    const [onFirstFocus, setOnFirstFocus] = useState(false);
    const { layout } = customSettings;
    const {
      backgroundBotColor,
      textBotColor,
      backgroundUserColor,
      textUserColor,
    } = layout;

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.type !== 'keydown') return;
        setIsMouseClick(false);

        const queryLi = document.querySelectorAll('.li-list-message');
        if (event.key === 'ArrowDown' && onFirstFocus) {
          setCountFocus((prev) => {
            if (prev === 0) {
              return 2;
            }

            if (prev === queryLi.length) {
              return prev;
            }
            return prev + 1;
          });
        }

        if (event.key === 'ArrowUp' && onFirstFocus) {
          setCountFocus((prev) => {
            if (prev - 1 === 0 || prev === 0) {
              return 1;
            }
            return prev - 1;
          });
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [onFirstFocus, isMouseClick]);

    useEffect(() => {
      const handleFocusChange = (event) => {
        const messageList = document.querySelector('.li-list-message');
        if (!messageList || !messageList.contains(event.target)) {
          setOnFirstFocus(false);
        }
      };

      document.addEventListener('focusin', handleFocusChange);

      return () => {
        document.removeEventListener('focusin', handleFocusChange);
      };
    }, []);

    useEffect(() => {
      const queryLi = document.querySelectorAll('.li-list-message');
      if (queryLi?.length && queryLi?.length >= countFocus) {
        const message = queryLi[countFocus - 1];
        if (message) {
          queryLi.forEach((li) => {
            li.style.border = 'none';
            li.classList.remove('has-focus-tab');
          });
          const messageToTranslate = message.getAttribute('data-testid');
          if (messageToTranslate && hasRybena()) {
            stopTranslate();
            setTimeout(() => {
              translate({ message: messageToTranslate });
            }, 1000);
          }
          message.classList.add('has-focus-tab');
        }
      }
    }, [countFocus]);

    return (
      <S.Message
        onMouseDown={() => setIsMouseClick(true)}
        fontSize={fontSize}
        backgroundBotColor={backgroundBotColor}
        textBotColor={textBotColor}
        backgroundUserColor={backgroundUserColor}
        textUserColor={textUserColor}
        className={cc([
          {
            'li-list-message': true,
            'message-user': message?.from === 'user',
            'message-bot': message?.from === 'bot',
            'message-user--webchat':
              message?.from === 'user' && channel === 'webchat',
            'message-bot--webchat':
              message?.from === 'bot' && channel === 'webchat',
            'message-user--whatsapp':
              message?.from === 'user' && channel === 'whatsapp',
            'message-bot--whatsapp':
              message?.from === 'bot' && channel === 'whatsapp',
            'has-focus': focus && !onFirstFocus,
          },
        ])}
        focus={focus}
        onFocus={(e) => {
          if (isMouseClick) {
            return;
          }

          setOnFirstFocus(true);

          const inputMessage = document.querySelectorAll('.input-message');
          const queryLi = document.querySelectorAll('.li-list-message');

          if (queryLi?.length) {
            queryLi.forEach((li) => {
              li.style.border = 'none';
              li.style.outline = 'none';
              li.style.boxShadow = 'none';
              li.classList.remove('has-focus-tab');
            });
          }

          if (inputMessage?.length) {
            inputMessage.forEach((li) => {
              li.style.border = 'none';
            });
          }

          setTimeout(() => {
            e.target.classList.add('has-focus-tab');
          }, 10);

          if (hasRybena()) {
            stopTranslate();
            setTimeout(() => {
              translate(message);
            }, 1000);
          }
        }}
        onBlur={(e) => {
          e.target.style.border = 'none';
          e.target.style.outline = 'none';
          e.target.style.boxShadow = 'none';
          e.target.classList.remove('has-focus-tab');
        }}
        data-testid={message.message}
        tabIndex={messages[0]?.id === message.id ? 0 : -1}
        id={message.id}
      >
        {message?.isStorageUserMedia ? (
          <DeliveredFile
            primaryColor={backgroundUserColor}
            secondaryColor={textUserColor}
          />
        ) : (
          <MessageComponent {...props} />
        )}
        {message.type !== 'TYPING' && (
          <TimeOnMessage
            time={message?.time}
            color={message?.from === 'user' ? textUserColor : textBotColor}
          />
        )}
      </S.Message>
    );
  };

  return memo(MessageWrapper);
};
