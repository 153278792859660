import React, { useState, useMemo, useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';

import { useSettingsContext } from '../../Context/SettingsContext';
import QuickAccess from './components/QuickAccess';
import InputFile from './components/InputFile';
import { getLineBreakSize } from '../../utils/getSizes';
import quickAccessRules from '../../constants/quickAccessRules';

import * as S from './styles';

const InteractionForm = ({ quickAccess, onSubmit, onQuickAccessClick }) => {
  const { bot, fontSize, customSettings } = useSettingsContext();
  const { webchatType, layout } = customSettings;
  const domPurify = DOMPurify.sanitize;

  const fontIsDisabled = layout?.fontIsDisabled || false;
  const textareaRef = useRef(null);
  const isKeyboardFocus = useRef(false);
  const breakLineSize = getLineBreakSize(fontSize);

  const [message, setMessage] = useState('');
  const [isOverScrollbar, setIsOverScrollbar] = useState(false);

  const hasFooter = bot?.channel?.poweredBy || false;

  function handleInput() {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  const handleSubmit = () => {
    const formattedMessage = domPurify(message, { ALLOWED_TAGS: [] });
    if (
      formattedMessage &&
      formattedMessage !== '' &&
      /\S/.test(formattedMessage)
    ) {
      onSubmit({
        message: formattedMessage,
        type: 'TEXT',
      });
    }
    setMessage('');
    textareaRef.current.style.height = 'auto';
  };

  const executeBreakLineAction = () => {
    setMessage(`${message} \n`);
    const textarea = textareaRef.current;
    const [currentHeight] = textarea.style.height.split('px');
    const current = currentHeight || 22;
    textarea.style.height = 'auto';
    textarea.style.height = `${parseInt(current, 10) + breakLineSize}px`;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.shiftKey) {
        executeBreakLineAction();
      } else {
        handleSubmit();
      }
    }
  };

  const quickAccessList = useMemo(() => {
    if (quickAccess) {
      const { sortingRules, quickAccessActive } = quickAccess;
      const activeRule = sortingRules || false;

      if (!activeRule || !quickAccessActive) return [];

      const activeRuleName = quickAccessRules[activeRule];
      return quickAccess[activeRuleName] || [];
    }
  }, [quickAccess]);

  const handleMouseMove = (event) => {
    const element = event?.target;
    const { clientX, clientY } = event;
    const { top, bottom, right } = element?.getBoundingClientRect();
    const isOverXScrollbar = clientX > right - 10 && clientX < right;
    const isOverYScrollbar =
      clientY > top && clientY < bottom && clientX > right - 10;
    setIsOverScrollbar(isOverXScrollbar || isOverYScrollbar);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        isKeyboardFocus.current = true;
      }
    };

    const handleMouseDown = () => {
      isKeyboardFocus.current = false;
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleFocus = (event) => {
    if (isKeyboardFocus.current) {
      event.target.style.border = '2px solid black';
      event.target.style.outline = '3px solid rgba(32, 32, 32, 0.7)';
      event.target.style.outlineOffset = '3px';
      event.target.style.border = 'none';
      event.target.style.borderRadius = '4px';
      event.target.style.transition =
        'box-shadow 0.2s ease-in-out, outline 0.2s ease-in-out, outline-offset 0.2s ease-in-out';
    }
  };

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  const handleBlur = (event) => {
    event.target.style.border = 'none';
    event.target.style.outline = 'none';
    event.target.style.outlineOffset = '0';
    event.target.style.boxShadow = 'none';
  };

  return (
    <S.Container
      onMouseMove={handleMouseMove}
      fontSize={fontSize}
      webchatType={webchatType}
      hasFooter={hasFooter}
      isOverScrollbar={isOverScrollbar}
      fontIsDisabled={fontIsDisabled}
    >
      {!!quickAccessList?.length && (
        <QuickAccess
          quickAccessList={quickAccessList}
          onQuickAccessClick={onQuickAccessClick}
        />
      )}
      <textarea
        type="text"
        name="message"
        className="input-message"
        placeholder="Digite algo..."
        maxLength={1000}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        title=""
        rows={1}
        onInput={handleInput}
        ref={textareaRef}
        data-testid="textarea"
        onFocus={(e) => {
          if (isKeyboardFocus.current) {
            e.target.style.outline = '3px solid rgba(32, 32, 32, 0.7)';
            e.target.style.outlineOffset = '3px';
            e.target.style.border = 'none';
            e.target.style.borderRadius = '4px';
            e.target.style.transition =
              'box-shadow 0.2s ease-in-out, outline 0.2s ease-in-out, outline-offset 0.2s ease-in-out';
          }

          const queryLi = document.querySelectorAll('.li-list-message');

          if (queryLi?.length) {
            queryLi.forEach((li) => {
              li.style.border = 'none';
            });
          }
        }}
        onBlur={(e) => {
          e.target.style.border = 'none';
          e.target.style.outline = 'none';
          e.target.style.outlineOffset = '0';
          e.target.style.boxShadow = 'none';
        }}
        tabIndex="0"
      />
      <div className="webchat_message_actions">
        {!bot?.channel?.disableAttachments && <InputFile onChange={onSubmit} />}

        <div
          onClick={handleSubmit}
          className="webchat_message_input_action"
          data-testid="submit-button"
        >
          <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ outline: 'none', border: 'none' }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            tabIndex="0"
            onKeyDown={(e) => {
              if (e?.code === 'Enter') {
                handleSubmit();
              }
            }}
          >
            <path
              d="M20.9609 9C20.9609 9.46875 20.6484 9.9375 20.1797 10.1328L2.71875 17.6719C2.5625 17.75 2.40625 17.75 2.25 17.75C1.89844 17.75 1.54688 17.6328 1.3125 17.3594C0.960938 17.0078 0.882812 16.4219 1.11719 15.9531L4.00781 10.2109L13.4609 9.03906L4.00781 7.82812L1.11719 2.08594C0.882812 1.61719 0.960938 1.03125 1.3125 0.679688C1.66406 0.289062 2.25 0.171875 2.71875 0.367188L20.2188 7.86719C20.6875 8.0625 20.9609 8.53125 20.9609 9Z"
              fill="#979AA5"
            />
          </svg>
        </div>
      </div>
    </S.Container>
  );
};

export default InteractionForm;
