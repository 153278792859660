import React, { useEffect, useRef, useState } from 'react';

import { useSettingsContext } from '../../Context/SettingsContext';
import Typography from '../Typography';
import CheckBox from '../CheckBox/index';
import Button from '../Button';

import * as S from './styles';

const Settings = ({ setSettingsIsOpen }) => {
  const { fontSize, setFontSize } = useSettingsContext();
  const closeButtonRef = useRef(null);
  const checkboxNormalRef = useRef(null);
  const checkboxLargeRef = useRef(null);
  const checkboxExtraLargeRef = useRef(null);
  const [focusIndex, setFocusIndex] = useState(0);

  const getLabel = (size) => {
    const getValue = () => {
      if (size === 'normal_size') return '100%';
      if (size === 'large_size') return '150%';
      if (size === 'extra_large_size') return '200%';
    };
    return (
      <div className="checkbox_options_row">
        <Typography variant="body-small" className={size}>
          {getValue(size)} -&nbsp;
        </Typography>
        <Typography variant="body-small" className={size}>
          Exemplo
        </Typography>
      </div>
    );
  };

  const resetBorders = () => {
    [checkboxNormalRef, checkboxLargeRef, checkboxExtraLargeRef].forEach(
      (ref) => {
        if (ref?.current) {
          ref.current.style.border = 'none';
          ref.current.style.outline = 'none';
          ref.current.style.outlineOffset = '0';
          ref.current.style.boxShadow = 'none';
        }
      }
    );
  };

  const applyBorder = (ref) => {
    if (ref?.current && ref?.current?.textContent !== 'Fechar configurações') {
      ref.current.style.outline = '3px solid rgba(32, 32, 32, 0.7)';
      ref.current.style.outlineOffset = '3px';
      ref.current.style.border = 'none';
      ref.current.style.borderRadius = '4px';
      ref.current.style.transition =
        'box-shadow 0.2s ease-in-out, outline 0.2s ease-in-out, outline-offset 0.2s ease-in-out';
    }
  };

  const focusElement = (ref) => {
    if (ref?.current) {
      ref?.current?.focus();
      resetBorders();
      applyBorder(ref);
    }
  };

  const handleKeyUp = (event) => {
    const refs = [
      null,
      closeButtonRef,
      checkboxNormalRef,
      checkboxLargeRef,
      checkboxExtraLargeRef,
    ];

    if (event.key === 'ArrowDown') {
      setFocusIndex((prev) => {
        if (prev === 4) {
          return prev;
        }
        const nextIndex = (prev + 1) % refs.length;
        focusElement(refs[nextIndex]);
        return nextIndex;
      });
    } else if (event.key === 'ArrowUp') {
      setFocusIndex((prev) => {
        if (prev === 1) {
          return prev;
        }
        const prevIndex = (prev - 1 + refs.length) % refs.length;
        focusElement(refs[prevIndex]);
        return prevIndex;
      });
    }

    if (event.key === 'Enter') {
      if (focusIndex - 1 === 1) {
        setFontSize('normal_size');
      } else if (focusIndex - 1 === 2) {
        setFontSize('large_size');
      } else if (focusIndex - 1 === 3) {
        setFontSize('extra_large_size');
      }
    }
  };

  const handleClick = () => {
    resetBorders();
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('click', handleClick);
    };
  }, [focusIndex]);

  return (
    <S.Container fontSize={fontSize}>
      <Button
        variant="outline"
        size="small"
        fullWidth
        onClick={() => setSettingsIsOpen(false)}
        className="mb-8 close_settings"
        ref={closeButtonRef}
      >
        <Typography
          variant="caption-default"
          color="primary-800-light"
          data-testid="close-settings"
        >
          Fechar configurações
        </Typography>
      </Button>
      <Typography variant="body-default-strong" className="mb-4">
        Acessibilidade
      </Typography>
      <Typography variant="body-small-strong" className="mb-8">
        Tamanho da fonte
      </Typography>
      <div className="checkbox_options">
        <CheckBox
          key="100%"
          value="100%"
          checked={fontSize === 'normal_size'}
          label={getLabel('normal_size')}
          onChange={() => setFontSize('normal_size')}
          checkRef={checkboxNormalRef}
          data-testid="normal_size"
        />
        <CheckBox
          key="150%"
          value="150%"
          checked={fontSize === 'large_size'}
          label={getLabel('large_size')}
          onChange={() => setFontSize('large_size')}
          checkRef={checkboxLargeRef}
          data-testid="large_size"
        />
        <CheckBox
          key="200%"
          value="200%"
          checked={fontSize === 'extra_large_size'}
          label={getLabel('extra_large_size')}
          onChange={() => setFontSize('extra_large_size')}
          checkRef={checkboxExtraLargeRef}
          data-testid="extra_large_size"
        />
      </div>
    </S.Container>
  );
};

export default Settings;
