import styled from 'styled-components';
import { getMessageFontSize, getMessagesHeight } from '../../utils/getSizes';

export const MessagesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  height: ${({ webchatType, hasFooter }) =>
    getMessagesHeight(webchatType, hasFooter)};
  padding: 8px 16px;
  margin: 0;
  overflow-y: auto;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 4px !important;
    background-color: #ffffff;
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid #dadce3;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #dadce3;
  }
`;

export const Message = styled.li`
  padding: 14px;
  margin-bottom: 8px;
  max-width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  white-space: pre-wrap;
  text-align: left;
  font-size: ${({ fontSize }) => getMessageFontSize(fontSize)};

  & p:first-child {
    margin: 0;
  }

  &.message-user {
    border-radius: 16px 4px 16px 16px;
    margin-left: auto;
  }

  &.message-bot {
    border-radius: 16px 16px 16px 4px;
    margin-right: auto;
  }

  &.message-user--webchat {
    background-color: ${({ backgroundUserColor }) => backgroundUserColor};
    color: ${({ textUserColor }) => textUserColor};
    a {
      color: ${({ textUserColor }) => textUserColor};
    }
  }

  &.message-bot--webchat {
    background-color: ${({ backgroundBotColor }) => backgroundBotColor};
    color: ${({ textBotColor }) => textBotColor};
    a {
      color: ${({ textBotColor }) => textBotColor};
    }

    .tidot {
      background-color: ${({ textBotColor }) => textBotColor};
    }
  }

  &.has-focus {
    border: ${({ textBotColor }) => `2px solid ${textBotColor}`};
    box-shadow: '0 0 10px rgba(0, 0, 0, 0.5)';
    border-radius: '5px';
  }

  &.has-focus-tab {
    border: ${({ textBotColor }) => `2px solid ${textBotColor} !important`};
  }
`;

export const LineWithDate = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BottonLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0px 16px 0px;
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 2px;
  background-color: #dadce3;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ContainerHistoricMessages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 1px;
  border-radius: 8px;
  background-color: #dadce3;
  width: 267px;
  gap: 8px;
`;

export const ContainerNoMoreMessages = styled.div`
  border: 1px dashed #979aa5;

  border-radius: 8px;
  background-color: #fff;
  width: 267px;
`;
