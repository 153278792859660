import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  p {
    text-align: ${({ from }) => from === 'user' && 'right'};
  }
`;

export const LinkMessage = styled.a`
  .linkMessage {
    color: ${({ color }) => color};
  }
  text-decoration: ${({ color }) => `underline ${color}`};
  :hover {
    text-decoration: ${({ color }) => `underline ${color}`};
  }
`;

export const NotLinkMessage = styled.p``;
export const Message = styled.div`
  display: inline-block;
  p {
    display: inline;
    white-space: pre-line;
  }
  a {
    display: inline;
  }
`;
