import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 45%;
  margin-left: -12px;
`;

export const Button = styled.div`
  background: ${(props) => props.mainColor};
  padding: 5px;
  margin: 3px 0;
  border-radius: 4px;
  cursor: pointer;
  transition: outline 0.1s ease-in-out, outline-offset 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;

  & > img {
    width: 35px;
  }

  &.keyboard-focus:focus {
    outline: 3px solid rgba(0, 0, 0, 0.7);
    outline-offset: 3px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }
`;
